import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import withContext from '../store/withContext';

const PageWrapper = styled.div`
  margin-top: 5rem;
  text-align: center;
  p {
    padding: 1rem;
  }
  .btn {
    margin: 2rem;
  }

  select {
    font-size: 1.4rem;
  }
  a {
    background-color: #142743;
    border-radius: 5px;
    color: #FFF;
    cursor: pointer;
    display: inline-block;
    margin: 1rem;
    padding: 1rem 2rem;
    text-decoration: none;
  }
`;


class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      site: 'Smitegame',
      loggedIn: false,
      route: null
    }
  }

  isLoggedIn = (updateLoggedIn) => {
    this.setState({
      loggedIn: updateLoggedIn
    })
  }

  updateRoute = (e) => {
    this.props.context.actions.updateRoute(e.target.value);
  }

  getURLParam = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[]/, '\\\[').replace(/[\]]/, '\\\]');
    let regexS = '[\\?&]' + name + '=([^&#]*)';
    let regex = new RegExp(regexS);
    let results = regex.exec(url);
    return results == null ? null : results[1];
  }

  createCookie = (name, value, days) => {
    let expires;
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = '; expires=' + date.toGMTString();
    }
    else {
      expires = '';
    }
    document.cookie = name + "=" + value + expires + ";path=/";
  }

  readCookie = (name) => {
    let nameEQ = name + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }

  parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

  componentDidMount() {
    let token = this.getURLParam("token");
    if (token || this.readCookie("jwt")) {
      let tokenData;
      if (token) {
        tokenData = this.parseJwt(token);
        this.createCookie("jwt", token, 7);
        window.history.pushState('hirez', 'Tools','/');
      }
      else {
        tokenData = this.parseJwt(this.readCookie("jwt"));
      }
      this.setState({
        loggedIn: true
      });
      // need to return these as part of the jwt
      // username, email, page permissions
      this.props.context.actions.authenticateLogin(true,tokenData.authorized_pages,tokenData.name);
    }
  }

  render() {
    return (
      <PageWrapper>
      {(this.props.context && this.props.context.authenticated) && 
        <>
          <div className="option-list">
            {this.props.context.availableTools && this.props.context.availableTools.map((tool, index) =>
            <React.Fragment key={index + tool}>
              {tool === 'wp-cache' &&
                <Link to="/wp-cache">Clear WP Cache</Link>
              }
              {(tool !== 'aoc' && tool !== 'olympians' && tool !== 'keys' && tool !== 'cs-header' && tool !== 'wp-cache' && tool !== 'localization') &&
              <Link to={`${tool}`}>{tool.charAt(0).toUpperCase() + tool.slice(1)}</Link>
              }
              {tool === 'olympians' && 
                <>
                <Link to={'council#olympians'}>Olympians Applications</Link>
                <Link to={'council-results#olympians'}>Olympian Results</Link>
                </>
              }
              {tool === 'aoc' && 
                <>
                  <Link to={'council#aoc'}>AOC Applications</Link>
                  <Link to={'council-results#aoc'}>AOC Results</Link>
                </>
              }
              {tool === 'keys' && 
                <>
                  <Link to={'/keys'}>Key Distribution</Link>
                </>
              }
            </React.Fragment>
            )}
          </div>
          {!this.props.context.availableTools &&
           <p>You do not have permissions to do anything here</p>
          }
        </>
      }
      {!this.props.context.authenticated && 
        <>
        <p>Please Log In to Do What You Need to Do</p>
        </>
      }
      </PageWrapper>
    );
  };
}

export default withContext(Home);
